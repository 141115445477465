import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'antd'
import startcase from 'lodash.startcase'
import qs from 'qs'

import {useFetch} from '../lib/use-fetch'
import Spinner from '../lib/spinner'
import OauthError from '../components/OauthError'
import {FormattedMessage} from 'react-intl'

function SocialLogin ({appId, authorizeUrl, redirectUrl}) {
  const {loading, responseBody, status} = useFetch('GET', `/apps/${appId}/providers/`)
  if (loading) {
    return <Spinner />
  }

  if (!Array.isArray(responseBody) || responseBody.length === 0 || status !== 200) {
    return <OauthError error='providers retrieval failed' errorDescription='' />
  }

  return (
    <div style={styles.container}>
      <h1>
        <FormattedMessage defaultMessage='Login' id='login.message' />
      </h1>
      <div style={styles.buttons}>
        {responseBody.map(provider => {
          const query = qs.stringify({
            appId,
            providerId: provider.id,
            redirect: redirectUrl
          }, {addQueryPrefix: true})
          return (
            <Button
              href={`${authorizeUrl}${query}`}
              icon={getIconByProviderType(provider.type)}
              key={provider.id}
              style={{margin: 5}}
            >
              <span style={{paddingLeft: 3}}>
                <FormattedMessage id='loginwith.button' values={{providerType: startcase(provider.id)}} />
              </span>
            </Button>
          )
        })}
      </div>
    </div>
  )
}

function getIconByProviderType (type) {
  switch (type) {
    case 'gitlab':
      return 'gitlab'
    case 'github':
      return 'github'
    default:
      return null
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column'
  }
}

SocialLogin.propTypes = {
  appId: PropTypes.string.isRequired,
  authorizeUrl: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string
}

export default SocialLogin
